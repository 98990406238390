import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import CardEmbed from "./CardEmbed";
import toast from "react-hot-toast";
import CardEmbedPreview from "./CardEmbedPreview";
import { useDispatch, useSelector } from "react-redux";
import { addWidget } from "../../../redux/actions/Content";

export default function DialogBoxEmbed({ isOpen, onClose, onTakeData, isIcon }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentFolder } = useSelector((state) => state.FileFolder);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [item, setItem] = useState({});
  const [inputValue, setInputValue] = useState("");
  const { user } = useSelector((state) => state.User);

  React.useEffect(() => {
    handleClickOpen();
  }, [isOpen, onClose]);

  const handleClickOpen = () => {
    if (isOpen) {
      setName("");
      setInputValue("");
    }
    if (onClose) {
      setName("");
      setInputValue("");
    }
  };

  const handleAdd = () => {
    const isWebPage = inputValue;
    if (name === "" || inputValue === "") {
      toast.error(t("views.dialogBoxEmbed.fieldsRequired"));
      return;
    }
    if (isWebPage) {
      setItems((previous) => [...previous, { title: name, inputValue }]);
      onTakeData({ title: name, inputValue, isIcon });

      const htmltostr = inputValue.replace(/"/g, '\\"').replace(/\n/g, "\\n").toString();
      dispatch(
        addWidget({
          name,
          type: "widget",
          widgetCat: "embed",
          widgetIcon: isIcon,
          url: "",
          html: htmltostr.replace(/\\"/g, '"').replace(/\\n/g, "\n"),
          folderId: currentFolder,
          user: user._id,
        })
      );
      setName("");
      setInputValue("");
      toast.success(t("views.dialogBoxEmbed.successfullyLoaded"));
      onClose();
    } else {
      setName("");
      setInputValue("");
      toast.error(t("views.dialogBoxEmbed.validHtmlRequired"));
    }
  };

  const handlePreviewOpen = () => {
    const isWebPage = isHTMLPage(inputValue);
    if (isWebPage) {
      setItem({ title: name, inputValue });
      setPreviewOpen(true);
      toast.success(t("views.dialogBoxEmbed.successfullyLoaded"));
    } else {
      toast.error(t("views.dialogBoxEmbed.errorCheck"));
    }
  };

  const isHTMLPage = (text) => {
    const htmlPattern = /<!DOCTYPE html>[\s\S]*<\/html>/i;
    const cssPattern = /<style\b[\s\S]*<\/style>/i;
    const jsPattern = /<script\b[\s\S]*<\/script>/i;

    const hasHTML = htmlPattern.test(text);
    const hasCSS = cssPattern.test(text);
    const hasJS = jsPattern.test(text);

    return hasHTML || hasCSS || hasJS;
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setItem({});
  };

  const handleAddConfirm = () => {
    setItems((previous) => [...previous, { title: name, inputValue }]);
    setName("");
    setInputValue("");
    setPreviewOpen(false);
    toast.success(t("views.dialogBoxEmbed.successfullyLoaded"));
  };

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={"./images/embedIcon.png"} alt="Logo" width={"150px"} />
        </div>

        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxEmbed.embed")}</DialogTitle>
        <DialogContent sx={{ minHeight: "30vh" }}>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ paddingTop: 3, fontWeight: "bold", marginRight: 7 }}>{t("views.dialogBoxEmbed.name")}*:</DialogContentText>
            <TextField
              id="outlined-basic"
              label={t("views.dialogBoxEmbed.giveYourEmbedName")}
              variant="outlined"
              fullWidth
              sx={{ width: "465px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxEmbed.embedCode")}*:</DialogContentText>
            <TextField
              id="standard-multiline-static"
              variant="outlined"
              multiline
              fullWidth
              value={inputValue}
              rows={5}
              sx={{ marginLeft: 1, width: "465px" }}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" onClick={handlePreviewOpen} sx={{ marginRight: 1 }}>
              {t("views.dialogBoxEmbed.help")}
            </Button>
            <Button variant="contained" color="primary" onClick={handlePreviewOpen}>
              {t("views.dialogBoxEmbed.preview")}
            </Button>
          </div>
          <div>
            <Button onClick={() => onClose(false)}>{t("views.dialogBoxEmbed.cancel")}</Button>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxEmbed.save")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: "10px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}>
        {items.map((item, index) => (
          <CardEmbed key={index} item={item} />
        ))}
      </div>

      <Dialog fullWidth open={previewOpen} onClose={handlePreviewClose}>
        <CardEmbedPreview item={item} />
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" onClick={handleAddConfirm}>
              {t("views.dialogBoxEmbed.confirm")}
            </Button>
          </div>
          <div>
            <Button onClick={handlePreviewClose}>{t("views.dialogBoxEmbed.cancel")}</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
